<template>
    <div
        v-click-outside="close"
        class="chargemap-dropdown"
        data-testid="chargemap-dropdown"
    >
        <slot
            name="button"
            :set-button-ref="setSlotButtonRef"
            :toggle-show-list="toggleShowList"
        />

        <Transition>
            <div
                v-if="shown"
                ref="dropdownRef"
                class="fixed mt-1 z-10 p-2 w-64 rounded-lg bg-white shadow-lg ring-opacity-5 focus:outline-none transition-opacity duration-150"
                :style="[positionStyle, { top: `${arrayPositions[0]}px` }]"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                data-testid="chargemap-dropdown"
                tabindex="-1"
            >
                <div
                    class="[&>*]:rounded-md [&>*]:p-2 flex flex-col gap-1 transition-all duration-150 transform"
                    role="none"
                >
                    <slot
                        name="actionsList"
                        :toggle-show-list="toggleShowList"
                    />
                </div>

                <slot />
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, nextTick } from 'vue'

const dropdownRef = ref<HTMLElement>()
const dropdownButtonRef = ref<HTMLElement>()

const setSlotButtonRef = (el: any) => {
    dropdownButtonRef.value = el?.$el
}

const props = defineProps({
    responsiveSwitch: {
        type: Boolean,
        default: false
    },
    position: {
        type: String,
        default: 'right',
        validator(value: string) {
            return ['left', 'right'].includes(value)
        }
    }
})

defineEmits(['showDropdown'])

const shown = ref(false)
const arrayPositions = ref<[number, number]>([-1000, -1000])

const close = () => {
    shown.value = false
}

const toggleShowList = () => {
    shown.value = !shown.value
}

const calculatePosition = () => {
    const buttonElement = dropdownButtonRef.value

    if (buttonElement) {
        arrayPositions.value = buttonElement.getBoundingClientRect().bottom
            ? [
                  buttonElement.getBoundingClientRect().bottom,
                  buttonElement.getBoundingClientRect().left
              ]
            : [-1000, -1000]
    }
}

const windowWidth = ref(window.innerWidth)

const handleResize = () => {
    windowWidth.value = window.innerWidth
}

const isDesktop = computed(() => windowWidth.value > 768)

watch(
    () => shown.value,
    async (value: boolean) => {
        shown.value = value

        await nextTick()

        if (value) {
            calculatePosition()

            document.addEventListener('wheel', () => {
                calculatePosition()
            })

            window.addEventListener('resize', () => {
                handleResize()
                calculatePosition()
            })
        } else {
            document.removeEventListener('wheel', () => {
                calculatePosition()
            })

            document.removeEventListener('resize', () => {
                handleResize()
                calculatePosition()
            })
        }
    },
    { immediate: true }
)

const positionStyle = computed(() => {
    let leftPosition =
        arrayPositions.value[1] - (dropdownRef.value?.clientWidth || 0)

    const buttonElement = dropdownButtonRef.value

    if (buttonElement) {
        leftPosition += buttonElement.clientWidth
    }

    switch (props.position) {
        case 'right':
            return props.responsiveSwitch
                ? `left: ${isDesktop.value ? arrayPositions.value[1] : leftPosition}px;`
                : `left: ${arrayPositions.value[1]}px;`
        case 'left':
            return props.responsiveSwitch
                ? `left: ${isDesktop.value ? `${leftPosition}` : arrayPositions.value[1]}px;`
                : `left: ${leftPosition}px;`
        default:
            return props.responsiveSwitch
                ? `left: ${isDesktop.value ? arrayPositions.value[1] : leftPosition}px;`
                : `left: ${leftPosition}px;`
    }
})
</script>

<style>
.chargemap-dropdown .filters-trigger {
  @apply inline-flex
            items-center
            justify-between
            gap-2
            w-full lg:min-w-60
            h-10
            rounded-md
            px-4 py-2
            font-medium
            text-slate-800
            text-sm
            bg-white
            hover:bg-slate-50
            shadow
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-offset-slate-100
            focus:ring-primary-500;
}
.chargemap-dropdown button {
  @apply flex
        gap-2
        w-full
        p-1
        leading-5
        font-medium
        rounded-md
        transition
        duration-150
        ease-in-out
        hover:bg-slate-50
        text-slate-800
        focus:outline-none;
}
.chargemap-dropdown .filters-button {
  @apply flex
        gap-2
        w-full
        p-2.5
        leading-5
        rounded-md
        transition
        duration-150
        ease-in-out
        hover:bg-slate-50
        focus:outline-none;
}
.chargemap-dropdown .filters-button svg {
  @apply invisible;
}
.chargemap-dropdown .filters-button--selected {
  @apply text-primary-600;
}
.chargemap-dropdown .filters-button--selected svg {
  @apply visible;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
